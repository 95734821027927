








import { Component, Vue } from "vue-property-decorator";
import EDLI from "@/components/EDLI/EDLI.vue";

@Component({
  components: {
    EDLI
  }
})
export default class EDLIView extends Vue {}
