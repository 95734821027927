



























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
@Component({
  components: {}
})
export default class EDLIView extends Vue {
  private selected: any = null;
  private verModal: boolean = false;
  private productoSeleccionado: any = {
    titulo: "",
    imgSrc: "",
    descripcion: "",
    encargada: {
      nombre: "",
      profesion: "",
      email: "",
      telefono: []
    }
  };
  private productosEdli: any = [
    {
      id: 1,
      titulo: "Fortalecimiento al tránsito a la vida independiente",
      imgSrc: "EDLI/Fortalecimiento-al-tránsito-a-la-vida-independiente.jpg",
      descripcion:
        "Este producto tiene como objetivo dirigir gestiones y acciones hacia la Promoción de la autonomía y vida independiente de personas con discapacidad. Orientado al financiamiento de servicios de apoyo de cuidado, asistencia y/o intermediación de personas con discapacidad entre 18 y 59 años, acceso a recursos complementarios para el Tránsito a la Vida independiente como acceder a servicios de monitoras de respiro, facilitadora familiar y adaptaciones del entorno, entre otros.",
      encargada: {
        nombre: "Marcela Díaz Morales",
        profesion: "Trabajadora Social",
        email: "edli@quilpue.cl",
        telefono: ["32-2186137", "32-2186368"],
        imagen: "EDLI/ENCARGADA_SOCIO_LABORAL_Y_TVI_MARCELA_DIAZ.jpg"
      }
    },
    {
      id: 2,
      titulo: "Fortalecimiento de la gestión socio laboral",
      imgSrc: "EDLI/Fortalecimiento-de-la-gestión-socio-laboral.jpg",
      descripcion:
        "Este producto tiene como objetivo fomentar la inserción sociolaboral de personas con discapacidad, sus familias y organizaciones en la comuna. Este producto tiene como finalidad fortalecer la gestión municipal en los procesos de intermediación laboral, en el marco de la Ley Nº 21.015 de Inclusión Laboral que incentiva la incorporación de personas con discapacidad en el mundo del trabajo. Se desarrolla de forma articulada entre la Oficina de Información Laboral Municipal (OMIL) y la Unidad de Discapacidad, vinculando el quehacer de éstas con el objetivo de aumentar la inclusión laboral de las personas con discapacidad del territorio.",
      encargada: {
        nombre: "Marcela Díaz Morales",
        profesion: "Trabajadora Social",
        email: "edli@quilpue.cl",
        telefono: ["32-2186137", "32-2186368"],
        imagen: "EDLI/ENCARGADA_SOCIO_LABORAL_Y_TVI_MARCELA_DIAZ.jpg"
      }
    },
    {
      id: 3,
      titulo:
        "Implementación de rehabilitación infantil con estrategia comunitaria",
      imgSrc: "EDLI/Implementación-infantil-con-estrategia-comunitaria.jpg",
      descripcion:
        "Este producto tiene como objetivo aumentar las prestaciones de rehabilitación en la comuna, mediante la implementación de una Sala de Rehabilitación Infantil que permita una inclusión social de niños, niñas y adolescentes (NNA) en sus procesos de autonomía e independencia, en la educación regular, en su participación familiar y comunitaria. El público objetivo son niños/as y adolescentes desde los 0 hasta los 17 años, además de contemplar dentro de las intervenciones a sus familiares o cuidadores. Las prestaciones que entrega la sala son: - Intervenciones de clínicas de rehabilitación individual y/o grupal. - Talleres a NNA y/o familiares o cuidadores sobre temáticas acordes a sus necesidades de intervención. - Orientación y consejería a las familias o cuidadores de los NNA que asisten a la sala de rehabilitación.",
      encargada: {
        nombre: "Fernanda Armijo Ramírez",
        profesion: "Terapeuta Ocupacional",
        email: "edli@quilpue.cl",
        telefono: ["32-2186137", "32-2186368"],
        imagen: "EDLI/ENCARGADA_SALA_RBC_FERNANDA_ARMIJO.jpg"
      }
    },
    {
      id: 4,
      titulo: "Fortalecimiento de la participación",
      imgSrc: "EDLI/Fortalecimiento-de-la-participación.jpg",
      descripcion:
        "Este producto busca fortalecer la participación de las personas con discapacidad, sus familiares, cuidadores y organizaciones sociales en la gestión municipal y en las instancias participativas de la comuna, promoviendo la igualdad de oportunidades, autonomía, vida independiente y evitando la discriminación arbitraria.",
      encargada: {
        nombre: "Cecilia Lecumberri Olivares",
        profesion: "Trabajadora Social",
        email: "edli@quilpue.cl",
        telefono: ["32-2186137", "32-2186368"],
        imagen: "EDLI/COORDINADORA_EDLI_CECILIA_LECUMBERRI.jpg"
      }
    },
    {
      id: 5,
      titulo: "Incubadora de cooperativas inclusivas",
      imgSrc: "EDLI/Incubadora-de-cooperativas-inclusivas.jpg",
      descripcion:
        "El propósito de este producto es implementar el modelo de Incubadora de Cooperativas Inclusivas con el fin de apoyar la creación y formalización de Cooperativas Inclusivas de Trabajo y/o Servicios. Se busca que las Cooperativas sean lugares inclusivos donde personas con discapacidad puedan ejercer su derecho al trabajo, así como impulsar emprendimientos asociativos entre personas con y sin discapacidad en la comunidad. Además, se promueve la incubación de futuras Cooperativas que integren a familiares, tutores y/o vecinos en un mismo espacio productivo.",
      encargada: {
        nombre: "Lorena Rivera Zepeda",
        profesion: "Geógrafo, Licenciada en Geografía",
        email: "edli@quilpue.cl",
        telefono: ["32-2186137", "32-2186368"],
        imagen: "EDLI/ENCARGADA_COOPERATIVAS_INCLUSIVAS_LORENA_RIVERA.jpg"
      }
    }
  ];

  private verDetalle(item: any) {
    this.productoSeleccionado = item;
    this.verModal = true;
  }

  private getImageUrl(imagePath: string): string {
    return require(`@/assets/${imagePath}`);
  }

  private redirectToExternal(url: string) {
    window.open(url, "_blank");
  }
}
